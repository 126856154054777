import Alpine from "alpinejs";
import Dropzone from "dropzone"
import "dropzone/dist/dropzone.css"

// Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files heress to upload";
// Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
// Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
// Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
// Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
// Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with {{statusCode}} code.";
// Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
// Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
// Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
// Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";

window.Alpine = Alpine;
window.Dropzone = Dropzone

Alpine.start();

require('./bootstrap');
